.Top100{
}

.Top100-logo {
  max-width: 1000px !important;
  height: auto;
  margin: auto;
  display: block;
}

.Top100-header {
  align-items: center;
}

.Top100-link {
}


